import { typedEntries } from "../utils/objects";
import {
  DetailedEntitlements,
  DetailedEntitlements as DetailedEntitlementsDto,
  EntitlementActualObject as EntitlementActualObjectDto,
  EntitlementDetails as EntitlementDetailsDto,
  ProductUsageReport as ProductUsageReportDto,
} from "./client";
import { dtoToNumberEntitlementValue, dtoToReclaimEditionStr } from "./team/Team.mutators";
import { EntitlementIntegrations, EntitlementSupport, ReclaimEditionStr } from "./team/Team.types";
import { EntitlementDetails } from "./Users";
import { EntitlementActualObject, ProductUsageReport, ProductUsageReportActuals } from "./Users.types";
import { QUESTS_CONFIG, QUESTS_DIRECTORY } from "../components/quests/quests.consts";
import { QuestConfig, QuestGroup, QuestsProgress } from "../components/quests/quests.types";
import { UserQuests } from "./client";

export const dtoToEntitlementActualObject = <T>(dto: EntitlementActualObjectDto): EntitlementActualObject<T> => ({
  requiredEdition: dtoToReclaimEditionStr(dto.requiredEdition),
  actualValue: dto.actualValue as T,
  allowedValueForCurrentEdition: dto.allowedValueForCurrentEdition as T,
  requiredEditionValue: dto.requiredEditionValue as T,
});

/**
 * Back-end passes `Infinity` as `"Infinity"`.
 * This function behaves exactly like
 * `dtoToEntitlementActualObject` only it can
 * also parse the string infinity values
 * @param dto The data transfer object
 * @returns a `EntitlementActualObject<number>` object
 */
export const dtoToNumberEntitlementActualObject = (
  dto: EntitlementActualObjectDto
): EntitlementActualObject<number> => ({
  requiredEdition: dtoToReclaimEditionStr(dto.requiredEdition),
  actualValue: dtoToNumberEntitlementValue(dto.actualValue),
  allowedValueForCurrentEdition: dtoToNumberEntitlementValue(dto.allowedValueForCurrentEdition),
  requiredEditionValue: dtoToNumberEntitlementValue(dto.requiredEditionValue),
});

export const dtoToProductUsageReportActuals = (
  dto: Record<string, EntitlementActualObjectDto>
): ProductUsageReportActuals => ({
  MAX_TEAM_SIZE: dto.MAX_TEAM_SIZE && dtoToNumberEntitlementActualObject(dto.MAX_TEAM_SIZE),
  SCHEDULER_WEEKS: dto.SCHEDULER_WEEKS && dtoToNumberEntitlementActualObject(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dto.MAX_TASKS && dtoToNumberEntitlementActualObject(dto.MAX_TASKS),
  MAX_CALENDARS: dto.MAX_CALENDARS && dtoToNumberEntitlementActualObject(dto.MAX_CALENDARS),
  MAX_SYNCS: dto.MAX_SYNCS && dtoToNumberEntitlementActualObject(dto.MAX_SYNCS),
  MAX_HABITS: dto.MAX_HABITS && dtoToNumberEntitlementActualObject(dto.MAX_HABITS),
  CUSTOM_BLOCKING: dto.CUSTOM_BLOCKING && dtoToEntitlementActualObject<boolean>(dto.CUSTOM_BLOCKING),
  MAX_SCHEDULING_LINKS: dto.MAX_SCHEDULING_LINKS && dtoToNumberEntitlementActualObject(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dto.MAX_1_ON_1_ORGANIZE && dtoToNumberEntitlementActualObject(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dto.MAX_1_ON_1_ATTEND && dtoToNumberEntitlementActualObject(dto.MAX_1_ON_1_ATTEND),
  INTEGRATIONS: dto.INTEGRATIONS && dtoToEntitlementActualObject<EntitlementIntegrations>(dto.INTEGRATIONS),
  SUPPORT: dto.SUPPORT && dtoToEntitlementActualObject<EntitlementSupport>(dto.SUPPORT),
  SSO: dto.SSO && dtoToEntitlementActualObject<boolean>(dto.SSO),
});

export const dtoToProductUsageReport = (dto: ProductUsageReportDto): ProductUsageReport => {
  const usageEdition = dtoToReclaimEditionStr(dto.usageEdition);
  const currentEdition = dtoToReclaimEditionStr(dto.currentEdition);
  const terminalEdition = dtoToReclaimEditionStr(dto.terminalEdition);
  const recommendedEdition = dtoToReclaimEditionStr(dto.recommendedEdition);

  return {
    ...dto,
    usageEdition,
    currentEdition,
    terminalEdition,
    recommendedEdition,
    actuals: dtoToProductUsageReportActuals(dto.actuals),
    terminalActuals: dtoToProductUsageReportActuals(dto.terminalActuals),
  };
};

export const dtoToEntitlementDetails = <N extends string>(dto: EntitlementDetailsDto): EntitlementDetails<N> => ({
  ...dto,
  minimumEdition: dto.minimumEdition as ReclaimEditionStr,
  name: dto.name as N,
});

export const dtoToDetailedEntitlements = (dto: DetailedEntitlementsDto): DetailedEntitlements =>
  typedEntries(dto).reduce((acc, [key, detailedEnt]) => {
    acc[key] = detailedEnt && dtoToEntitlementDetails(detailedEnt);
    return acc;
  }, {} as DetailedEntitlements);

export const dtoToQuests = (dto: UserQuests): QuestsProgress => {
  const quests = dto.completedQuests;
  const config: Partial<QuestsProgress> = {};

  Object.keys(QUESTS_DIRECTORY).forEach((groupKey) => {
    config[groupKey] = { quests: {} };
    QUESTS_DIRECTORY[groupKey].forEach((questKey) => {
      const complete = !!quests.find((q) => q === questKey);
      const qCfg: QuestConfig<QuestGroup> = QUESTS_CONFIG[groupKey].quests.find((q) => q.id === questKey);
      const steps = qCfg.steps.map((s) => s.id);
      config[groupKey].quests[questKey] = { complete, steps };
    });
  });

  return config as QuestsProgress;
};
